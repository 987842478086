<template>
  <div class="bg-blue-50 px-6">
    <div class="bg-white rounded-lg shadow-md mb-2 flex lg:flex-row flex-col justify-between items-center">
        <div class="px-12 py-6">
            <h1 
            class="text-blue-700 text-2xl sm:text-3xl 2xl:text-4xl font-normal text-left">
              <faIcon
              icon="ticket-alt"
              type="fas"
              class="text-red-500 text-3xl"
              >
              </faIcon>
              Órdenes de trabajo anuladas
            </h1>
            <p class="pt-1 text-md sm:text-lg text-gray-600 text-left">Listado de OTs anuladas.</p>
        </div>
    </div>
    <ListTable
      :columns="fields"
      :filters="filters"
      rowsGetter="otsAnuladas/ordenesTrabajoAnuladas"
      :buttons="buttons"
      :managementButtons="true"
      :useStorage="true"
      :columnFilters="true"
      @mostrar-detalle="verOT"
      @filter-input="setFilters"
      @dates-input="setDates"

    >
    <template v-slot:ot%valorTotal="data">
      <p class="text-gray-900 font-medium text-xs 2xl:text-base whitespace-no-wrap">
        {{ `$ ${formatearCifra(data.value)}` }}
      </p>
    </template>

    </ListTable>
    <Pagination
      path=""
      :meta="meta"
      :links="links"
      action="otsAnuladas/paginateOrdenesTrabajoAnuladas"
      v-if="meta && meta.last_page > 1"
    />
    <Modal 
    v-if="modalVisibleOT"
    class="z-40"
    >
      <template v-slot:header>
        <div class="flex justify-between">
          <div class="text-left pl-2 sm:pl-6 sm:pt-3">
              <h3 class="font-medium text-2xl sm:text-3xl text-gray-700">Detalle de esta OT</h3>
              <p class="mt-1 max-w-2xl text-sm sm:text-base text-gray-500">Estos son los datos con los que se generó esta OT.</p>
          </div>
          <div class="text-3xl text-red-600">
            Nº {{ this.otSeleccionadaVer.numeroOt }}
          </div>
        </div>
      </template>

      <template v-slot:body>
        <div>
          <DetalleOT
          :ot="this.otSeleccionadaVer"
          />
        </div>
      </template>

      <template v-slot:botones>
        <div>
          <div class="flex sm:flex-row-reverse">
              <button @click="this.cerrarModalOt" type="button" class="mt-3 justify-center rounded-md border shadow-sm px-4 py-2 text-base font-semibold focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm border-transparent bg-gray-600 hover:bg-gray-500 text-white">
                Atrás
              </button>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import ListTable from "@/components/ListTable";
import Pagination from "@/components/Pagination";
import Modal from "@/components/Modal";
import DetalleOT from "@/components/OTs/DetalleOT";

import { separarPorMiles } from '@/utils/helpers';
import { mapGetters } from "vuex";

import store from "@/store/index";
import OTService from "@/services/OrdenTrabajoService";

export default {
  name: "ListaOrdenesTrabajoAnuladas",
  setup() {
    let initials = store.getters['otsAnuladas/filters']
    let dates = store.getters['otsAnuladas/dates']

    return {
      fields: {
        'ot%numeroOt' : 'ot',
        'ot%fechaCreacion' : 'fecha ingreso',
        'ot%horaCreacion' : 'hora ingreso',
        'ot%nombre' : 'Nombre cliente',
        'ot%apellidoPaterno' : 'Apellido p.',
        'ot%apellidoMaterno' : 'Apellido m.',
        'ot%telefono' : 'Teléfono',
        'usuario%name' : 'usuario',
        'fechaCreacion' : 'fecha anulación',
        'horaCreacion' : 'hora anulación',
        'ot%valorTotal' : 'valor total'
      },
      filters: [
        {
          "placeholder": "N°",
          "type": "number",
          "field": "ot_num",
          "initial": initials && initials.ot_num ? initials.ot_num.value : ""
        },
        {
            "type": "none"
        },
        {
            "type": "none"
        },
        {
            "type": "none"
        },
        {
            "type": "none"
        },
        {
            "type": "none"
        },
        {
            "type": "none"
        },
        {
            "type": "none"
        },
        {
            "type": "none"
        },
        {
            "placeholder": "Por fecha",
            "field": "created_at",
            "type": "date-range",
            "initial": dates ? dates : null
        }
      ],
    }
  },
  data() {
    return {
      rows: [],
      modalVisibleOT: false,
      modalVisibleAbono: false,
      otSeleccionadaVer: null,
      abonosSeleccionadosVer: null,
      numeroOTSeleccionadaAnular: null,
      estadoSeleccionado: null,
      abono: {
        numeroOT: '',
        idUsuario: '',
        montoAbono: 0,
        medioPagoAbono: 'Efectivo'
      },
      esVisibleConfirmacionAbono: false,
      esVisibleConfirmacionCambioEstado: false,
      esVisibleConfirmacionAnular: false,
      keyComponenteAbonos: 0,
      estadosOTDisponibles: [],
      estaInhabilitadoBotonAbono: false,
      estaInhabilitadoBotonCambioEstado: false,
      estaInhabilitadoBotonAnularOT: false
    }
  },
  computed: {
    ...mapGetters("otsAnuladas", ["error", "meta", "links"]),
    ...mapGetters("auth", ["isMontaje"]),
    buttons() {
      let buttons = [
        {
          "text": "Ver",
          "event": "mostrar-detalle"
        }
      ]

      return buttons
    }
  },
  methods: {
    mostrarListaOT() {
      store.dispatch("otsAnuladas/getOrdenesTrabajoAnuladas", 1);
    },
    verOT(value) {
      OTService.getOT(value.ot.numeroOt).then((response) => {
        this.otSeleccionadaVer = response.data.data;
        this.estadoSeleccionado = this.otSeleccionadaVer.estadoLog;
        return this.otSeleccionadaVer;
      }).then((otSeleccionada) => {
        if(otSeleccionada != null){
          this.abrirModalOt();
        }
      });
    },
    obtenerClaseEstado(key) {
      switch (key) {
        case 'Finalizada':
           return 'bg-gray-300 text-gray-800';

        case 'En espera de retiro':
          return 'bg-green-200 text-green-800';

        case 'Montaje OK':
          return 'bg-yellow-400 text-yellow-800';

        case 'Recibida en Montajes':
          return 'bg-blue-300 text-blue-800'; 

        case 'Despachada a Montajes':
          return 'bg-blue-300 text-blue-800';

        case 'Crédito simple':
          return 'bg-purple-300 text-purple-800';

        case 'Cristales solicitados':
          return 'bg-blue-300 text-blue-800';

        case 'En inicio':
          return 'bg-blue-300 text-blue-800';

        case 'Montaje fallido':
          return 'bg-red-300 text-red-700';

        case 'Cristales recibidos con error':
          return 'bg-red-300 text-red-700 text-xs';
      
        default:
          break;
      }
    },
    cerrarModalOt() {
      this.modalVisibleOT = false;
    },
    abrirModalOt() {
      this.modalVisibleOT = true;
    },
    setDates(dates) {
      store.dispatch("otsAnuladas/setDates", dates);
      store.dispatch("otsAnuladas/getOrdenesTrabajoAnuladas", 1);
    },
    setFilters(filters) {
      store.dispatch("otsAnuladas/setFilters", filters);
      this.mostrarListaOT();
    },
    formatearCifra(cifra) {
      return separarPorMiles(cifra);
    }
  },
  beforeRouteEnter(to, from, next) {
    const currentPage = parseInt(to.query.page) || 1
    store.dispatch("otsAnuladas/getOrdenesTrabajoAnuladas", currentPage).then(() => {
      to.params.page = currentPage;
      next();
    })
  },
  components: {
    ListTable,
    Pagination,
    Modal,
    DetalleOT,
  }
}
</script>